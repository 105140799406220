
import { defineComponent, PropType } from 'vue'
import { Order } from '@/services/OrderService/types'
import Card from '@/components/globals/atoms/Card.vue'
import {reformatDate} from '@/services/Utils'

export default defineComponent({
    components: {Card},

    props: {
        order: {
            type: Object as PropType<Order>,
            required: true
        }
    },

    computed: {
        reformatedDate(){
            return reformatDate(this.order.createdAt)
        },
    }
})
