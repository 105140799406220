import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53db6376"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "account-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ader_icon = _resolveComponent("ader-icon")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, {
    hasFooter: true,
    class: _normalizeClass(_ctx.cardClasses),
    onClick: _ctx.connectAccount
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ader_icon, {
          iconName: _ctx.accountObject.icon,
          height: 48,
          width: 48
        }, null, 8, ["iconName"]),
        _createElementVNode("h5", null, _toDisplayString(_ctx.accountObject.name), 1)
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.footerClasses)
      }, _toDisplayString(_ctx.statusText), 3)
    ]),
    _: 1
  }, 8, ["class", "onClick"]))
}