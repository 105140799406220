
import { defineComponent, Ref, ref } from 'vue';
import { UserService } from '@/services/UserService/UserService';
import { User } from '@/services/UserService/types'
import { ConnectedUser } from '@/services/AuthService/types';
import { AuthService } from '@/services/AuthService/AuthService';

export default defineComponent({
  name: 'Home',
  setup() {
    const user: Ref<User> = ref<User>({});
    const connectedUser: Ref<ConnectedUser> = ref<ConnectedUser>({})
    return {
      user,
      connectedUser
    }  
  },

  mounted(){
    const authService = new AuthService()
    authService.getConnectedUser().then(
      connectedUser => this.connectedUser = connectedUser
    )

    const userID = this.$route.query.userID as string
    const userService = new UserService()
    userService.getUser(userID).then(
      user => this.user = user
    )
  }
});
