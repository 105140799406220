
import OrderCard from "@/components/orders/OrderCard.vue";
import AderIcon from "@/components/globals/atoms/AderIcon.vue"
import Paginator from "@/components/globals/molecules/Paginator.vue"
import CaseSideView from "@/components/globals/templates/CaseSideView.vue"
import OrderTabDetailContent from "@/components/orders/OrderTabDetailContent.vue"
import { Order, PaginationData } from "@/services/OrderService/types"
import { OrderService } from "@/services/OrderService/OrderService";
import { defineComponent, ref, Ref } from "vue";

export default defineComponent({
    components: {
        OrderCard, Paginator, CaseSideView, AderIcon, OrderTabDetailContent,
    },
    
    setup() {
        const orderService = new OrderService();
        const loading = ref(false);

        const orders: Ref<Order[]> = ref<Order[]>([]);
        const paginationData = ref<PaginationData>();
        const currentPageIndex = ref<number>(1);

        const showingOrderDetail = ref(false);
        const orderToDetail = ref<Order>();


        return {
            orders,
            orderService,
            currentPageIndex,
            paginationData,
            loading,
            showingOrderDetail,
            orderToDetail
        };
    },

    mounted() {
        this.fetchAndUpdateOrders()
    },
    methods: {
        fetchAndUpdateOrders(){
            this.loading = true;
            const userID = this.$route.query.userID as string;
            this.orderService.getOrders(userID, this.currentPageIndex).then(response => {
                this.paginationData = response.paginationData
                this.orders = response.pageData
                this.loading = false;
            })
        },

        showOrderDetail(order: Order){
            this.orderToDetail = order;
            this.showingOrderDetail = true;
        },

        goToOrderDetailSbopifyLink(){
            window.open(this.orderToDetail?.orderStatusUrl)
        }
    },

    watch: {
        currentPageIndex(){
            this.fetchAndUpdateOrders()
        }
    }
});
