import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a7e80f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "container" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      disabled: _ctx.current == 1,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:current', _ctx.current - 1)))
    }, " < ", 8, _hoisted_2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
      return (_openBlock(), _createElementBlock("button", {
        key: page,
        onClick: ($event: any) => (_ctx.changePage(page)),
        class: _normalizeClass(_ctx.getButtonClass(page))
      }, _toDisplayString(_ctx.renderPageNumber(page)), 11, _hoisted_3))
    }), 128)),
    _createElementVNode("button", {
      disabled: _ctx.current == _ctx.pages[_ctx.pages.length-1],
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:current', _ctx.current + 1)))
    }, " > ", 8, _hoisted_4)
  ]))
}