import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d2d3e40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["height", "width"]
const _hoisted_3 = ["d", "fill"]
const _hoisted_4 = ["height", "width"]
const _hoisted_5 = ["d", "fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tooltip = _resolveComponent("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tooltip)
      ? (_openBlock(), _createBlock(_component_tooltip, {
          key: 0,
          class: "tooltip",
          label: _ctx.tooltip,
          position: _ctx.tooltipPosition
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock("svg", {
              class: _normalizeClass(_ctx.svgClass),
              height: _ctx.height,
              width: _ctx.width,
              viewBox: "0 0 24 24",
              preserveAspectRatio: "none"
            }, [
              _createElementVNode("path", {
                d: _ctx.icon,
                fill: _ctx.color
              }, null, 8, _hoisted_3)
            ], 10, _hoisted_2))
          ]),
          _: 1
        }, 8, ["label", "position"]))
      : (_openBlock(), _createElementBlock("svg", {
          key: 1,
          class: _normalizeClass(_ctx.svgClass),
          height: _ctx.height,
          width: _ctx.width,
          viewBox: "0 0 24 24",
          preserveAspectRatio: "none"
        }, [
          _createElementVNode("path", {
            d: _ctx.icon,
            fill: _ctx.color
          }, null, 8, _hoisted_5)
        ], 10, _hoisted_4))
  ]))
}