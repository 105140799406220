import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31e65bf8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "row header" }
const _hoisted_3 = { class: "header-content" }
const _hoisted_4 = {
  key: 0,
  class: "row content"
}
const _hoisted_5 = {
  key: 2,
  class: "row footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ader_icon = _resolveComponent("ader-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.showSubView)
          ? (_openBlock(), _createBlock(_component_ader_icon, {
              key: 0,
              iconName: "left-arrow",
              tooltip: "Volver a Lista de Pedidos",
              tooltipPosition: "is-right",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideSubView()))
            }))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.header), 1)
      ]),
      _renderSlot(_ctx.$slots, "append-header", { class: "append-header" }, undefined, true)
    ]),
    (_ctx.showSubView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "sub-view", {}, undefined, true)
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass('row content ' + (_ctx.hasFooter?'reduced-height':''))
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2)),
    (_ctx.hasFooter && !_ctx.showSubView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}