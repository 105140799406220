import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Orders from '../views/frames/Orders.vue'
import Onboarding from '../views/frames/Onboarding.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/frames/orders/',
    name: 'Order List',
    component: Orders
  },
  {
    path: '/frames/onboarding/',
    name: 'Onboarding',
    component: Onboarding
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
