function toCamel(str: string) {
    return str.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
}

function isObject(obj: any) {
    return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
}
function isArray (a: any) {
  return Array.isArray(a);
}

export function keysToCamel(obj: any): any {
    if (isObject(obj)) {
      const n = Object();
  
      Object.keys(obj)
        .forEach((k) => {
          n[toCamel(k)] = keysToCamel(obj[k]);
        });
  
      return n;
    }
    else if (isArray(obj)) {
      return obj.map((i: any) => {
        return keysToCamel(i);
      });
    }
    
    return obj;
}

export function getHeaders(authToken: string): Headers{

  return new Headers({
    'Authorization': 'Token ' + authToken, 
  })
}

export function withThousandSeparator(number: number): string{
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export function fixedLenght(number: number, lenght: number): string {
  return ("0".repeat(lenght) + number).slice(-lenght)
}

export function reformatDate(date: string): string{
  const _date = new Date(date)

  const days = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"]
  const day = days[_date.getDay()]

  const months = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep",
                  "oct", "nov", "dic"]
  const month = months[_date.getMonth()]
  
  const dayNumber = fixedLenght(_date.getDate(), 2)
  const year = _date.getFullYear()
  const hours = fixedLenght(_date.getHours(), 2)
  const minutes = fixedLenght(_date.getMinutes(), 2)
  return `${day} ${dayNumber} ${month} ${year}, a las ${hours}:${minutes}`
}