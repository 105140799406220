
import { defineComponent } from 'vue'
import AderIcon from "@/components/globals/atoms/AderIcon.vue"

export default defineComponent({
    components: {AderIcon},

    props: {
        header: {
            type: String,
            required: true
        },

        hasFooter: {
            type: Boolean,
            default: false 
        },

        showSubView: {
            type: Boolean,
            default: false
        }
    },

    emits: ['update:showSubView'],

    setup() {
        return {

        }
    },

    methods: {
        hideSubView(){
            this.$emit('update:showSubView', false)
            console.log("Emitido")
        }
    }
})
