
import { defineComponent } from 'vue'

export default defineComponent({
    
    props: {
        hasTitle: {
            default: false,
            type: Boolean
        },
        hasFooter: {
            default: false,
            type: Boolean
        }

    }
})
