
export type AllowedAccount = 'facebook' | 'whatsapp' | 'instagram' | 'chat' |
                              'email' | 'twitter' | 'twitter_search' |
                              'app_store' | 'play_store';

export enum AccountStatus {
    CONNECTED = 'connected',
    INPROGRESS = 'in_progress',
    DISCONNECTED = 'disconnected'
}
export type AllowedStatus = AccountStatus.CONNECTED | AccountStatus.INPROGRESS |
                            AccountStatus.DISCONNECTED;

export interface Account {
    name: AllowedAccount;
    connected: AllowedStatus;
}

export interface Establishment {
    id: string;
    name: string;
    address: string;
    onboardingNetworks: Account[];
}