
import { defineComponent, PropType } from 'vue';
import Card from '@/components/globals/atoms/Card.vue';
import { Account, AllowedAccount, AccountStatus } from '@/services/EstablishmentService/types';
import { IframeMessageService } from "@/services/IframeMessageService/IframeMessageService";
import AderIcon from '@/components/globals/atoms/AderIcon.vue';

const accountMap: Record<AllowedAccount, Record<string, string>> = {
    "facebook": {
        "name": "Facebook",
        "icon": "social-facebook"
    },
    "whatsapp": {
        "name": "WhatsApp",
        "icon": "social-whatsapp"
    },
    "twitter": {
        "name": "Twitter",
        "icon": "social-twitter"
    },
    "chat": {
        "name": "Chat",
        "icon": "social-chat"
    },
    "email": {
        "name": "Email",
        "icon": "social-email"
    },
    "instagram": {
        "name": "Instagram",
        "icon": "social-instagram"
    },
    "twitter_search": {
        "name": "Twitter Search",
        "icon": "social-twitter"
    },
    "app_store": {
        "name": "App Store",
        "icon": "social-app-store"
    },
    "play_store": {
        "name": "Play Store",
        "icon": "social-play-store"
    },
}

export default defineComponent({
    components: {Card, AderIcon},

    props: {
        account: {
            type: Object as PropType<Account>,
            required: true
        },
        section: {
            type: String as PropType<string>,
            default: 'onboarding',
            required: true
        }
    },

    computed: {
        accountObject() {
            let object: Record<string, string> = {
                "name": "undefined",
                "icon": "undefined"
            };
            try {
                object = accountMap[this.account.name];
            } catch (error) {
                console.log(error);
            }
            return object;
        },
        accountConnected(): boolean {
            return (
                this.account.connected === AccountStatus.CONNECTED
            );
        },
        statusText(): string {
            const statusMap: Record<AccountStatus, string> = {
                [AccountStatus.CONNECTED]: "¡Canal conectado!",
                [AccountStatus.DISCONNECTED]: "Conectar",
                [AccountStatus.INPROGRESS]: "En proceso",
            }
            return (
                statusMap[this.account.connected]
            );
        },
        cardClasses(): string[] {
            return [this.accountConnected ? 'connected' : '', 'accountCard']
        },
        footerClasses(): string[] {
            return [this.accountConnected ? 'connected' : '', 'connectBtn']
        }
    },

    methods: {
        connectAccount() {
            if(this.accountConnected) return;
            const messaging_service = new IframeMessageService();
            messaging_service.sendAccountConnectionMessage(
                this.section,
                this.account.name
            );
        }
    }

})
