
import {defineComponent, ref, PropType} from 'vue'

type paginatorLength = "s" | "m" | "l" | "xl"
const PAGES_ACORDING_TO_SIZE = {"s": 4, "m": 6, "l": 8, "xl": 10}
const HIDDEN_PAGES_INDICATOR = -1;


export default defineComponent({
    props: {
        current: {
            type: Number,
            required: true
        },

        nPages: {
            type: Number,
            required: true
        },

        size: {
            type: String as PropType<paginatorLength>,
            default: "s",
        }
    },
    
    emits: ['update:current'],

    setup(){
        const pages: Array<number> = []

        const maxNPages = ref(4)

        return {
            maxNPages,
            pages
        }
    },

    methods: {
        renderPageNumber(page: number): string | number{
            return page != HIDDEN_PAGES_INDICATOR ? page : "..."
        },

        changePage(page: number){
            if(page != HIDDEN_PAGES_INDICATOR){
                this.$emit('update:current', page)
            }
        },

        getButtonClass(pageIndex: number): string{
            if(pageIndex == this.current){
                return "current"
            }
            if(pageIndex == HIDDEN_PAGES_INDICATOR){
                return "hidden-pages-indicator"
            }
            return ""
        },

        limitPagesToShow(){
            this.pages = Array.from({length: this.nPages}, (x, i) => i + 1);
        
            if(this.maxNPages >= 0){
                const currentPageIndex = this.current - 1
                const pagesToHide = {
                    right: (this.nPages - 1) - (currentPageIndex + this.maxNPages/2),
                    left: currentPageIndex - this.maxNPages/2
                }

                const unhiddenPages = {
                    right: - Math.min(pagesToHide.right-1, 0),
                    left: - Math.min(pagesToHide.left-1, 0)
                }

                const pagesToHideWithFixedSize = {
                    right: pagesToHide.right - unhiddenPages.left,
                    left: pagesToHide.left - unhiddenPages.right 
                }
                
                if(this.current < this.nPages - this.maxNPages/2 - 1 && pagesToHideWithFixedSize.right > 0){
                    this.pages.splice(
                        currentPageIndex + this.maxNPages/2 + unhiddenPages.left, 
                        pagesToHideWithFixedSize.right,
                        HIDDEN_PAGES_INDICATOR
                    )
                }

                if(this.current > this.maxNPages/2 + 2 && pagesToHideWithFixedSize.left > 0){
                    this.pages.splice(
                        1,
                        pagesToHideWithFixedSize.left, 
                        HIDDEN_PAGES_INDICATOR
                    )
                }
            }
        },

        refreshSize(){
            this.maxNPages = PAGES_ACORDING_TO_SIZE[this.size]
        }
    },

    watch: {
        current(){
            this.limitPagesToShow();
        },

        size(){
            this.refreshSize()
            this.limitPagesToShow()
        }
    },

    created() {
        this.refreshSize()
        this.limitPagesToShow()  
    }
})
