
import { defineComponent,  provide } from 'vue'

export default defineComponent({
  setup() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    provide('token', params.authToken)    
  },
})
