<template>
    <div :class="buttonClasses">
        <div class="button-content">
            <div class="button-icon" v-if="hasIcon">
                <ader-icon iconName="plus"></ader-icon>
            </div>
            <div class="button-text" v-if="hasText">
                {{content}}
            </div>
        </div>
    </div>
  
</template>

<script>
import { defineComponent } from 'vue';
import AderIcon from '@/components/globals/atoms/AderIcon.vue';

export default defineComponent({
    components: {AderIcon},
    
    props: {
        content: {
            type: String,
            required: false
        },
        hasIcon: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: 'primary'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        hasText() {
            return this.content !== undefined
        },
        buttonClasses() {
            return ['button', this.buttonClass, this.disabled ? 'disabled' : '',]
        }
    },

})
</script>

<style lang="scss" scoped>
.button {
    padding: 14px 24px;
    font-weight: 500;
    font-size: 16px;
    font-family: Rubik;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.0015em;
    border-radius: 8px;
    box-shadow: $shadow-elevation-1;
    transition: all 0.2s;

    &:not(.disabled) {
        cursor: pointer;
    }

    &.primary {
        background-color: $color-primary-50;
        color: white;

        &:deep(path) {
            fill: white; 
        }
        
        &:hover:not(.disabled),
        &:focus:not(.disabled) {
            background-color: $color-primary-40;
            box-shadow: $shadow-elevation-2;
        }
        
        &:active:not(.disabled) {
            background-color: $color-primary-40;
            box-shadow: none;
        }
        
        &.disabled {
            background-color: $color-primary-90;
            color: $color-neutral-20;

            &:deep(path) {
                fill: $color-neutral-20;
            }
        }
    }

    &.secondary {
        color: $color-primary-50;
        border: 1px solid $color-primary-50;

        &:deep(path) {
            fill: $color-primary-50;; 
        }

        &:hover:not(.disabled),
        &:focus:not(.disabled) {
            background-color: $color-primary-40;
            box-shadow: $shadow-elevation-2;
            color: white;
        }
        
        &:active:not(.disabled) {
            background-color: $color-primary-40;
            box-shadow: none;
            color: white;
        }

        &:hover:not(.disabled),
        &:focus:not(.disabled),
        &:active:not(.disabled) {
            &:deep(path) {
                fill: white; 
            }
        }
        
        &.disabled {
            background-color: $color-bg-100;
            border-color: $color-primary-90;
            color: $color-neutral-30;

            &:deep(path) {
                fill: $color-neutral-30;; 
            }
        }
    }

    &.tertiary {
        color: $color-primary-50;
        text-decoration-line: underline;
        box-shadow: none;
        border: none;

        &:deep(path) {
                fill: $color-primary-50; 
            }

        &:hover:not(.disabled),
        &:focus:not(.disabled),
        &:active:not(.disabled) {
            color: $color-primary-40;

            &:deep(path) {
                fill: $color-primary-40; 
            }
        }
        
        &.disabled {
            color: $color-neutral-30;

            &:deep(path) {
                fill: $color-neutral-30;
            }
        }
    }

    .button-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        box-sizing: border-box;

        .button-icon {
            display: flex;
            justify-content: center;
        }
    }
}

</style>