import { keysToCamel } from "@/services/Utils"
import { API_URL } from "@/configs/UrlConfig"
import { ConnectedUser } from "@/services/AuthService/types"
import { BaseService } from "../BaseService"

export class AuthService extends BaseService{

    async getConnectedUser(): Promise<ConnectedUser> {
        const url = `${API_URL}/api/v1/user/`

        return fetch(url, { 
            method: 'GET', 
            headers: this.getHeaders(),
            mode: 'cors'
        }).then(response => response.json())
          .then(json => keysToCamel(json))
    }
}

