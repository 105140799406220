import { keysToCamel } from "@/services/Utils"
import { API_URL } from "@/configs/UrlConfig"
import { User } from "@/services/UserService/types"
import { BaseService } from "../BaseService"

export class UserService extends BaseService{
    
    async getUser(userID: string): Promise<User> {
        const url = `${API_URL}/api/v1/user/${userID}/`
        
        return fetch(url, { 
            method: 'GET', 
            headers: this.getHeaders() , 
            mode: 'cors'
        }).then(response => response.json())
          .then(json => keysToCamel(json))
    }
}