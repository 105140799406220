
import { defineComponent, PropType } from 'vue'
import { icons } from "@/services/AderIcons"

type TooltipPosition = "is-top" | "is-bottom" | "is-left" | "is-right";

export default defineComponent({
    props: {
        width: {
            default: 24,
            type: Number
        },

        height: {
            default: 24,
            type: Number
        },

        iconName: {
            required: true,
            type: String
        },

        color: {
            default: "#0A0B0D",
            type: String
        },

        tooltip: {
            type: String
        },

        tooltipPosition: {
            default: "is-top",
            type: String as PropType<TooltipPosition>
        }

    },


    computed: {
        icon(){
            return icons[this.iconName as string]
        },

        hasClickFunction(): boolean{
            return (this.$attrs && this.$attrs.onClick) as boolean
        },

        svgClass(): string {
            if(this.hasClickFunction){
                return "clickable"
            }
            return ""
        }
    }
})
