
import { defineComponent, PropType } from 'vue'
import OrderProductCard from "@/components/orders/OrderProductCard.vue"
import { Order } from '@/services/OrderService/types'
import { reformatDate } from '@/services/Utils'

export default defineComponent({
    components: {OrderProductCard},

    props: {
        order: {
            required: true,
            type: Object as PropType<Order>
        }
    },

    computed: {

        shippingCost(): number{
            const total = Number(this.order.totalPrice)
            const subtotal = Number(this.order.subtotalPrice)
            const tax = Number(this.order.totalTax)
            const discount = Number(this.order.totalDiscounts)
            return total + discount - subtotal - tax 
        },

        reformatedDate(): string{
            return reformatDate(this.order.createdAt)
        }
    }
})
