import { inject } from "vue";
import { getHeaders } from "./Utils";

export class BaseService{

    token: string;

    constructor(){
        const localToken = inject('token')
        
        console.assert(localToken != undefined, "Token undefined");
        
        this.token = localToken as string;
    }

    getHeaders(): Headers {
        return getHeaders(this.token)
    }

    // TODO: feth request on this class
}