
import { defineComponent, PropType } from 'vue'
import {Product} from "@/services/OrderService/types"
import AderIcon from "@/components/globals/atoms/AderIcon.vue"

export default defineComponent({
    components: {AderIcon},

    props: {
        product: {
            required: true,
            type: Object as PropType<Product>
        },

        currency: {
            default: "$",
            type: String
        }
    },

    setup() {
        return {

        }
    },
})
