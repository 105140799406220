
import { defineComponent, ref, Ref } from "vue";
import { Account } from "@/services/EstablishmentService/types";
import { EstablishmentService } from "@/services/EstablishmentService/EstablishmentService";
import { IframeMessageService } from "@/services/IframeMessageService/IframeMessageService";
import AccountCard from "@/components/accounts/AccountCard.vue";
import Button from "@/components/globals/atoms/Button.vue";
import AderIcon from "@/components/globals/atoms/AderIcon.vue";

export default defineComponent({
    components: {
        'card': AccountCard,
        'adereso-button': Button,
        'ader-icon': AderIcon
    },

    setup() {
   
        const establishmentService = new EstablishmentService();
        const iframeService = new IframeMessageService();
        let onboardingNetworks: Ref<Account[]> = ref<Account[]>([])
        const section = "onboarding"
        
        return {
            onboardingNetworks,
            establishmentService,
            section,
            iframeService
        };
    },

    mounted() {
        this.fetchEstablishment()
    },

    methods: {
        fetchEstablishment(){
            this.establishmentService.getEstablishment().then(response => {
                this.onboardingNetworks = response.onboardingNetworks
            })
        },

        closeModal() {
            this.iframeService.sendCloseWindowMessage(this.section)
        },
        
        goToAdmin() {
            this.iframeService.sendRedirectMessage(
                this.section,
                '#/admin/channels/'
            )
        }
    },

});
