import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import VueCustomTooltip, {TooltipOptions} from "@adamdehaven/vue-custom-tooltip"

const opt: TooltipOptions = {
    name: 'tooltip',
    color: '#fff',
    background: '#000',
    borderRadius: 100,
    fontWeight: 400,
  }

createApp(App).use(router).use(VueCustomTooltip, opt).mount('#app')
