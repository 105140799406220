import { BaseService } from "../BaseService";
import { keysToCamel } from "../Utils";
import { API_URL } from "@/configs/UrlConfig"
import { Establishment } from "./types";

export class EstablishmentService extends BaseService {
    
    async getEstablishment(): Promise<Establishment> {
        const url = `${API_URL}/api/v1/establishment/`

        return fetch(url, { 
            method: 'GET', 
            headers: this.getHeaders(),
            mode: 'cors'
        }).then(response => response.json())
            .then(json => keysToCamel(json))
    }
}