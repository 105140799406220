import { MessageData } from "./types";

export class IframeMessageService {
    
    sendMessage(section: string, message: MessageData): void {
        const event_type = section + 'Event';
        // const event = new CustomEvent(event_type, {detail: message});
        const data = {
            type: event_type,
            message: message
        }
        window.parent.postMessage(data, '*');
    }

    sendAccountConnectionMessage(section: string, account: string): void {
        const message = {
            account: account,
            action: 'account_connection'
        }
        this.sendMessage(section, message);
    }

    sendCloseWindowMessage(section: string): void {
        const message = {
            action: 'close_window'
        }
        this.sendMessage(section, message);
    }

    sendRedirectMessage(section: string, url: string): void {
        const message = {
            url: url,
            action: 'redirect'
        }
        this.sendMessage(section, message);
    }
}