import { API_URL } from "@/configs/UrlConfig";
import { BaseService } from "../BaseService";
import { keysToCamel } from "../Utils";
import { OrderRequestResponse } from "./types";

export class OrderService extends BaseService {
    
    async getOrders(userID="1", page: number): Promise<OrderRequestResponse> {
        const url = `${API_URL}/api/v1/orders/${userID}/${page}`
        
        return fetch(url, {
            method: 'GET',
            headers: this.getHeaders(),
            mode: 'cors'
        }).then(response => response.json())
          .then(json => keysToCamel(json))
    }
    
}